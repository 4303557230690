import { memo } from "react";
import PropTypes from "prop-types";
import { BADGE_DEFAULT_COLORS } from "app/constants";
import classNames from "classnames";
import "./ColoredProductBadge.scss";

const ColoredProductBadge = ({
	label,
	colorStyle = {
		txtColor: BADGE_DEFAULT_COLORS.TEXT,
		bgColor: BADGE_DEFAULT_COLORS.BACKGROUND,
		borderColor: BADGE_DEFAULT_COLORS.BORDER,
	},
	pictoUrl,
}) => {
	return label ? (
		<div
			className={classNames({
				"colored-product-badge": true,
				"colored-product-badge--picto": pictoUrl,
			})}
			style={{
				color: colorStyle.txtColor,
				backgroundColor: colorStyle.bgColor,
				border: colorStyle.borderColor && `1px solid ${colorStyle.borderColor}`,
			}}
			data-testid="colored-product-badge"
		>
			{pictoUrl && <img src={pictoUrl} alt={label} className="colored-product-badge__img" />}
			{label}
		</div>
	) : null;
};

ColoredProductBadge.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	colorStyle: PropTypes.shape({
		txtColor: PropTypes.string,
		bgColor: PropTypes.string,
		borderColor: PropTypes.string,
	}),
	pictoUrl: PropTypes.string,
};

export default memo(ColoredProductBadge);
