import { ACCEPT_COOKIES_POLICY } from "../../../actionTypes";

const initialState = false;

export default function slideshowReducer(state = initialState, action) {
	switch (action.type) {
		case ACCEPT_COOKIES_POLICY:
			return true;
		default:
			return state;
	}
}
