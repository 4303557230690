import PropTypes from "prop-types";
import { Children, memo } from "react";
import "./Badge.scss";

const Badge = ({ children, style }) => {
	return (
		<div className="badge" style={style}>
			{Children.toArray(children)
				.filter(child => child !== null)
				.map((item, idx) => (
					<div key={idx} className="badge__item">
						{item}
					</div>
				))}
		</div>
	);
};

Badge.propTypes = {
	style: PropTypes.object,
	children: PropTypes.node,
};

export default memo(Badge);
