import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import { useAnalytics } from "app/utils/analytics/useAnalytics";

const MAX_WIDTHS = {
	small: 768,
	medium: 978,
	large: 1440,
	xlarge: 1440,
};

const IMG_STYLES = {
	display: "none",
};

const BackgroundProgressiveImg = ({
	src,
	sizes = {},
	onLoad = () => {},
	thumbnail = "",
	resolution,
	backgroundPosition = "inherit",
	offset = 1000,
	overflow,
	cloudinaryOptions = [],
	quality, // @see https://cloudinary.com/documentation/image_optimization#use_q_auto_automatic_quality_and_encoding
	productUri,
	gradient,
}) => {
	const { track } = useAnalytics();
	const [loaded, setLoaded] = useState(false);
	const cloudinarify = useCallback(() => {
		let cloudinaryConfig = [];

		const size = sizes && sizes[resolution];

		if (size && size.width) {
			cloudinaryConfig.push(`w_${size.width}`);
		} else {
			cloudinaryConfig.push(`w_${MAX_WIDTHS[resolution]}`);
		}

		if (size && size.height) {
			cloudinaryConfig.push(`h_${size.height}`);
		}

		if (src) {
			let options = cloudinaryConfig;

			if (cloudinaryOptions) {
				options = cloudinaryConfig.concat(cloudinaryOptions);
			}

			return addOptionsToCloundinaryURL(src, options, {
				quality: quality,
			});
		}

		return "";
	}, [sizes, cloudinaryOptions, resolution, quality, src]);

	const onImageLoad = () => setLoaded(true);

	useEffect(() => {
		if (loaded && typeof onLoad === "function") {
			onLoad();
		}
	}, [loaded]);

	const source = cloudinarify();
	const backgroundImgUrl = `url("data:image/jpeg;base64,${thumbnail}")`;

	const backgroundImgCloudinaryUrl = `url("${source}")`;

	const backgroundImageStyle = {
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		height: "100%",
		transition: "opacity 1s ease",
		opacity: loaded ? 1 : 0,
		backgroundImage: `${[gradient, backgroundImgCloudinaryUrl].filter(Boolean).join(",")}`,
		backgroundPosition: backgroundPosition,
	};

	return (
		<div
			className="background-progressive-image"
			style={{
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100%",
				minHeight: "100%",
				backgroundImage: `${[gradient, backgroundImgUrl].filter(Boolean).join(",")}`,
				backgroundPosition: backgroundPosition,
			}}
			data-testid="background-progressive-image"
		>
			{// Evite de télécharger des images inutiles lorsque la resolution n'est pas encore connue ou lorsque
			// la resolution n'est pas définie dans la props sizes
			resolution !== RESOLUTION.UNKNOWN && sizes[resolution] !== undefined ? (
				<LazyLoad once offset={offset} overflow={overflow}>
					<div
						className="background-progressive-image__image"
						style={backgroundImageStyle}
					>
						<img
							onError={() =>
								track("image_not_loaded", {
									url: source,
									uri: productUri,
								})
							}
							style={IMG_STYLES}
							src={source}
							onLoad={onImageLoad}
						/>
					</div>
				</LazyLoad>
			) : (
				false
			)}
		</div>
	);
};

BackgroundProgressiveImg.propTypes = {
	src: PropTypes.string,
	sizes: PropTypes.shape({
		small: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		medium: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		large: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		xlarge: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
	}),
	onLoad: PropTypes.func,
	thumbnail: PropTypes.string,
	resolution: PropTypes.string,
	backgroundPosition: PropTypes.string,
	offset: PropTypes.number,
	overflow: PropTypes.bool,
	cloudinaryOptions: PropTypes.arrayOf(PropTypes.string),
	quality: PropTypes.string, // @see https://cloudinary.com/documentation/image_optimization#use_q_auto_automatic_quality_and_encoding
	productUri: PropTypes.string,
	gradient: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(BackgroundProgressiveImg);
