import { useFlagship } from "@flagship.io/react-sdk";
import { memo, useCallback, useContext, useState } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import classNames from "classnames";
import groupBy from "lodash/groupBy";
import { isServerSide } from "app/utils/utils";
import "./DevInfos.scss";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";
import Button from "app/pages/.shared/form/Button";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const DevInfos = () => {
	const [expand, toogleExpand] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const { flagsData = [], visitorId } = useFlagship();

	const { envVars = {}, strategy, partnerCode } = useContext(AppGlobalsContext);

	const environment = envVars.ENVIRONMENT;

	const showDevInfos =
		environment.includes("test") ||
		environment.includes("canary") ||
		environment.includes("preprod") ||
		environment.includes("e2e");

	const useCases = groupBy(flagsData, "variationName");

	const toggle = useCallback(() => {
		toogleExpand(!expand);
	}, [expand]);

	const devInfosClass = classNames("dev-infos", {
		"dev-infos--collapse": !expand,
	});

	const clearStorage = useCallback(() => {
		setLoading(true);
		var cookies = document.cookie.split("; ");
		for (var c = 0; c < cookies.length; c++) {
			var d = window.location.hostname.split(".");
			while (d.length > 0) {
				var cookieBase =
					encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
					"=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
					d.join(".") +
					" ;path=";
				var p = location.pathname.split("/");
				document.cookie = cookieBase + "/";
				while (p.length > 0) {
					document.cookie = cookieBase + p.join("/");
					p.pop();
				}
				d.shift();
			}
		}

		localStorage.clear();
		sessionStorage.clear();

		setInterval(() => {
			setLoading(false);
			location.reload();
		}, 1000);
	}, []);

	return (
		showDevInfos && (
			<div className={devInfosClass}>
				{!isServerSide && (
					<div>
						<Typography
							variant={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
							className="dev-infos__release"
						>
							release : {window.__VERSION__}
						</Typography>
						<div className="dev-infos__close" onClick={toggle}>
							{expand ? <IconArrowUp /> : <IconArrowDown />}
						</div>
					</div>
				)}

				<div className="dev-infos__env">
					{partnerCode} - {strategy}
				</div>

				{Object.keys(useCases).length > 0 && (
					<>
						<Typography
							variant={TYPOGRAPHY_VARIANTS.REGULAR}
							isBold
							className="dev-infos__title"
						>
							Use cases flagship for visitorID : {visitorId}
						</Typography>
						<div className="dev-infos__list">
							{Object.keys(useCases).map((useCase, index) => {
								return (
									<div key={index} className="dev-infos__item">
										<div className="dev-infos__slug">{useCase}</div>
										<ul className="dev-infos__flags">
											{useCases[useCase].map((flagData = {}, index) => {
												return (
													<li key={index} className="dev-infos__flag">
														{flagData.key} :{" "}
														<b>{JSON.stringify(flagData.value)}</b>
													</li>
												);
											})}
										</ul>
									</div>
								);
							})}
						</div>
					</>
				)}

				<div className="dev-infos__clear-storage-cta">
					<Button variant="primary" onClick={clearStorage} loading={isLoading}>
						Clear cookies and reload the page
					</Button>
				</div>
			</div>
		)
	);
};

DevInfos.propTypes = {};

export default memo(DevInfos);
