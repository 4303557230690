import { memo, useCallback, useState } from "react";
import IconPhoneFull from "app/pages/.shared/static/icons/IconPhoneFull";
import Button from "app/pages/.shared/form/Button";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Modal from "app/pages/.shared/Modal/Modal.jsx";
import ClickToCallContainer from "./ClickToCallContainer.jsx";

import "./ClickToCallCta.scss";

const ClickToCallCta = ({ officeHours = [] }) => {
	const [modalOpened, setModalOpen] = useState(false);

	const handleOpenModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	return (
		<div className="click-to-call-cta">
			<div className="click-to-call-cta__container">
				<div className="click-to-call-cta__schedules">
					{officeHours.length > 0 ? (
						officeHours[0]
					) : (
						<FormattedMessage id="click.to.call.cta.schedules" />
					)}
				</div>
				<Button
					variant="primary"
					onClick={handleOpenModal}
					loading={false}
					className="click-to-call-cta__button"
					data-testid="click-to-call-button"
				>
					<IconPhoneFull height={20} width={20} />
					<FormattedMessage id="click.to.call.cta.value" />
				</Button>
				<Modal
					opened={modalOpened}
					handleClose={handleCloseModal}
					title={<FormattedMessage id="click.to.call.cta.value" />}
				>
					<ClickToCallContainer handleClose={handleCloseModal} />
				</Modal>
			</div>
		</div>
	);
};

ClickToCallCta.propTypes = {
	officeHours: PropTypes.array,
};

export default memo(ClickToCallCta);
