import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProfile, saveProfile } from "app/pages/Account/Profile/profileActionCreators";
import { parsePhoneNumber } from "react-phone-number-input/max";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import useClickToCall from "./useClickToCall";
import PropTypes from "prop-types";

import ClickToCall from "./ClickToCall";

import { getCallCenter } from "app/pages/Booking/bookingSelectors";

const ClickToCallContainer = props => {
	const {
		brandCode,
		marketingCode,
		shop,
		isAuthenticated,
		getProfile,
		saveProfile,
		callCenter,
	} = props;

	useEffect(() => {
		if (isAuthenticated) {
			getProfile();
		}
	}, [isAuthenticated]);

	const useClickToCallProps = useClickToCall({
		brandCode,
		marketingCode,
		shopCode: shop,
		isAuthenticated,
		saveProfile,
		callCenter,
	});

	return <ClickToCall {...useClickToCallProps} {...props} />;
};

ClickToCallContainer.propTypes = {
	shop: PropTypes.string,
	brandCode: PropTypes.string,
	marketingCode: PropTypes.string,
	initialValues: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	saveProfile: PropTypes.func,
	getProfile: PropTypes.func,
	callCenter: PropTypes.object,
};

const mapStateToProps = state => {
	let numberAnalysis = { number: "" };

	if (state?.profile?.address?.phone?.length) {
		numberAnalysis = parsePhoneNumber(state.profile.address.phone, state.shop.slice(3, 5));
	}

	const initialValues = {
		phone: numberAnalysis.number,
	};

	return {
		initialValues,
		shop: state.shop,
		brandCode: state.brand?.code,
		marketingCode: state.partner.code,
		isAuthenticated: isAuthenticated(state),
		callCenter: getCallCenter(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ getProfile, saveProfile }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClickToCallContainer);
