import { memo, useContext } from "react";
import useCarouselDots from "app/utils/hooks/useCarouselDots";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./CarouselDots.scss";
import appGlobalsContext from "app/AppGlobalsContext";

const CarouselDots = ({ totalSlides, currentSlide, size, margin, padding }) => {
	const maxVisibleDots = Math.min(5, totalSlides); // Limite le nombre de dots visibles au nombre total de slides ou à 5, selon le plus petit
	const { isRTL } = useContext(appGlobalsContext);
	const {
		defaultHolderStyle,
		getHolderStyle,
		dots,
		defaultDotsStyle,
		getDotsStyle,
		getDotStyle,
		isSmall,
		isMedium,
		isBig,
		isActive,
		isVisible,
	} = useCarouselDots({
		total: totalSlides,
		current: currentSlide,
		visible: maxVisibleDots,
		size,
		margin,
		padding,
		isRTL,
	});

	return (
		<div
			style={{
				...defaultHolderStyle,
				...getHolderStyle,
				...(isRTL ? { direction: "rtl" } : {}),
			}}
			className="carousel-dots"
		>
			{dots.map((dot, index) => {
				const dotClassName = classNames("carousel-dots__dot", {
					"carousel-dots__dot--active": isActive(dot),
					"carousel-dots__dot--visible": isVisible(dot),
					"carousel-dots__dot--small": isSmall(dot),
					"carousel-dots__dot--medium": isMedium(dot),
					"carousel-dots__dot--big": isBig(dot),
				});

				return (
					<div
						key={index}
						style={{ ...defaultDotsStyle, ...getDotsStyle }}
						className="carousel-dots__item"
					>
						<div
							className={dotClassName}
							style={{
								...getDotStyle,
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};
CarouselDots.propTypes = {
	totalSlides: PropTypes.number,
	currentSlide: PropTypes.number,
	size: PropTypes.number,
	margin: PropTypes.number,
	padding: PropTypes.number,
};

export default memo(CarouselDots);
