import { Fragment } from "react";
import { animated, useSpring } from "react-spring";
import PropTypes from "prop-types";
import { xyAxisPropTypes } from "app/utils/propTypes";

const Flipper = props => {
	const { isFront, frontComponent, backComponent, overAxis, config } = props;

	const { transform, opacity, zIndex, pointerEvents } = useSpring({
		opacity: isFront ? 1 : 0,
		transform: `perspective(600px) rotate${overAxis}(${isFront ? 0 : 0.5}turn)`,
		zIndex: isFront ? 1 : -1,
		config: { mass: 5, tension: 500, friction: 80, ...config },
	});

	const FrontAnimated = animated(frontComponent);
	const frontSpring = {
		opacity,
		transform,
		zIndex,
		pointerEvents: isFront ? "auto" : "none",
		position: "absolute",
		willChange: "transform, opacity, z-index",
	};

	const BackAnimated = animated(backComponent);
	const backSpring = {
		opacity: opacity.interpolate(opacity => 1 - opacity),
		transform: transform.interpolate(transform => `${transform} rotate${overAxis}(0.5turn)`),
		zIndex: zIndex.interpolate(zIndex => -zIndex),
		pointerEvents,
		position: "absolute",
		willChange: "transform, opacity, z-index",
	};

	return (
		<Fragment>
			<FrontAnimated style={frontSpring} />
			<BackAnimated style={backSpring} />
		</Fragment>
	);
};

Flipper.defaultProps = {
	overAxis: "Y",
	config: {},
};

Flipper.propTypes = {
	isFront: PropTypes.bool.isRequired,
	frontComponent: PropTypes.any.isRequired,
	backComponent: PropTypes.any.isRequired,
	overAxis: xyAxisPropTypes,
	config: PropTypes.object,
};

export default Flipper;
