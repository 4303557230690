import { FormattedMessage } from "react-intl";
import InfoNotice from "app/pages/.shared/InfoNotice/InfoNotice.jsx";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import "./ServiceUnavailable.scss";
import PropTypes from "prop-types";

const ServiceUnavailable = ({ officeHours = [] }) => {
	return (
		<InfoNotice
			icon={<IconInfo />}
			title={<FormattedMessage id="click.to.call.service.unavailable.title" />}
		>
			<div className="service-unavailable__content">
				<div>
					<FormattedMessage
						id="click.to.call.service.unavailable.part1"
						values={{
							// eslint-disable-next-line react/no-multi-comp
							b: chunks => <b>{chunks}</b>,
						}}
					/>
				</div>
				<b>{officeHours.length > 0 && officeHours[0]}</b>
				<div>
					<FormattedMessage
						id="click.to.call.service.unavailable.part2"
						values={{
							// eslint-disable-next-line react/no-multi-comp
							b: chunks => <b>{chunks}</b>,
						}}
					/>
				</div>
			</div>
		</InfoNotice>
	);
};

ServiceUnavailable.propTypes = {
	officeHours: PropTypes.array,
};

export default ServiceUnavailable;
