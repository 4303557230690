import { createSelector } from "reselect";

const getPartner = state => state.partner;

export const isDepositEnabled = createSelector(
	[getPartner],
	partner => {
		return partner?.enableDepositDisplay;
	}
);
