import { Fragment, memo } from "react";
import PropTypes from "prop-types";
import "./AsidePanel.scss";
import classNames from "classnames";
import useBlockScroll from "app/utils/hooks/useBlockScroll";

const AsidePanel = ({ children, isOpen, className, ...props }) => {
	const asideClass = classNames({
		"aside-panel": true,
		"aside-panel--pushed": isOpen,
		...(className && { [className]: true }),
	});

	useBlockScroll({ isModalOpen: isOpen });

	const overlay = classNames("aside-panel-overlay", { "aside-panel-overlay--visible": isOpen });
	return (
		<Fragment>
			<div className={asideClass} {...props}>
				{children}
			</div>
			<div className={overlay} />
		</Fragment>
	);
};

AsidePanel.propTypes = {
	isOpen: PropTypes.bool,
	className: PropTypes.string,
	"data-testid": PropTypes.string,
};

export default memo(AsidePanel);
