import { BG_GRADIENT_ANIMATION } from "app/constants";
import { gradientAnimation } from "app/utils/propTypes";
import { useState, useRef, useEffect, memo } from "react";
import className from "classnames";
import "./BackgroundGradient.scss";

const BackgroundGradient = ({ animation = BG_GRADIENT_ANIMATION.NONE }) => {
	const containerRef = useRef(null);
	const [mousePosition, setMousePosition] = useState({});

	useEffect(() => {
		const handleMouseMove = e => setMousePosition({ x: e.pageX, y: e.pageY });
		if (typeof window !== "undefined" && animation === BG_GRADIENT_ANIMATION.MOUSE_MOVE) {
			window.addEventListener("mousemove", handleMouseMove);
		}

		return () => {
			if (typeof window !== "undefined" && animation === BG_GRADIENT_ANIMATION.MOUSE_MOVE) {
				window.removeEventListener("mousemove", handleMouseMove);
			}
		};
	}, []);

	const mouseMoveStyle = {
		background: `radial-gradient(52.92% 129.59% at ${Math.round(
			(mousePosition.x / (containerRef?.current?.clientWidth || 1)) * 100
		)}% ${Math.round(
			(mousePosition.y / (containerRef?.current?.clientHeight || 1)) * 100
		)}%, #007bc4 0%, rgba(0, 92, 146, 0) 100%)`,
	};

	return (
		<div ref={containerRef} className="background-container">
			<div
				className={className({
					"background__radial-gradient": true,
					"background__radial-gradient--animation-auto":
						animation === BG_GRADIENT_ANIMATION.AUTO,
				})}
				{...animation === BG_GRADIENT_ANIMATION.MOUSE_MOVE && { style: mouseMoveStyle }}
			/>
		</div>
	);
};

BackgroundGradient.propTypes = {
	animation: gradientAnimation,
};

export default memo(BackgroundGradient);
